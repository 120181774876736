import React from "react";

export default function ResumeSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="391.411"
      height="365.427"
      viewBox="0 0 391.411 365.427"
    >
      <g id="undraw_resume_re_hkth" transform="translate(-0.003 -0.003)">
        <path
          id="Path_119"
          data-name="Path 119"
          d="M407.594,101.136l-11.977,6.535L248.056,188.183l-11.977,6.535a25.015,25.015,0,0,0-9.966,33.9L336,430.015a25.015,25.015,0,0,0,33.9,9.966l.031-.017,171.454-93.549.031-.017a25.015,25.015,0,0,0,9.966-33.9L441.5,111.1a25.015,25.015,0,0,0-33.9-9.966Z"
          transform="translate(-223.063 -98.086)"
          fill="#f2f2f2"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M417.7,114.11l-14.186,7.74-143.144,78.1-14.186,7.74a17.813,17.813,0,0,0-7.1,24.142L348.972,433.229a17.814,17.814,0,0,0,24.142,7.1l.031-.017L544.6,346.761l.031-.017a17.814,17.814,0,0,0,7.1-24.142L441.841,121.207A17.813,17.813,0,0,0,417.7,114.11Z"
          transform="translate(-229.722 -104.745)"
          fill="#fff"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M524.751,229.285l-63.945,34.89a4.161,4.161,0,1,1-3.986-7.305l63.946-34.89a4.161,4.161,0,1,1,3.986,7.305Z"
          transform="translate(-334.409 -157.408)"
          fill="#f2f2f2"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M552.773,237.195,473.76,280.306A4.161,4.161,0,1,1,469.775,273l79.013-43.111a4.161,4.161,0,0,1,3.986,7.305Z"
          transform="translate(-340.637 -161.211)"
          fill="#f2f2f2"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M360.316,275.523l-58.954,32.167a1.994,1.994,0,0,0-.8,2.705l25.1,46.01a1.994,1.994,0,0,0,2.705.8l58.954-32.167a1.994,1.994,0,0,0,.8-2.705l-25.1-46.01a1.994,1.994,0,0,0-2.705-.8Z"
          transform="translate(-260.208 -183.279)"
          fill="#f2f2f2"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M564.758,341.726,478.024,389.05a4.161,4.161,0,0,1-3.986-7.305l86.734-47.324a4.161,4.161,0,0,1,3.986,7.305Z"
          transform="translate(-342.687 -211.469)"
          fill="#f2f2f2"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M592.78,349.636l-101.8,55.545a4.161,4.161,0,1,1-3.986-7.305l101.8-55.545a4.161,4.161,0,1,1,3.986,7.305Z"
          transform="translate(-348.916 -215.272)"
          fill="#f2f2f2"
        />
        <circle
          id="Ellipse_37"
          data-name="Ellipse 37"
          cx="17.653"
          cy="17.653"
          r="17.653"
          transform="translate(84.651 181.032)"
          fill="#f2f2f2"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M724.74,263.788H529.356a25.015,25.015,0,0,0-24.987,24.987V518.2a25.015,25.015,0,0,0,24.987,24.987H724.74A25.015,25.015,0,0,0,749.727,518.2V288.775a25.015,25.015,0,0,0-24.987-24.987Z"
          transform="translate(-358.313 -177.754)"
          fill="#e6e6e6"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M731.4,277.642H536.017a17.813,17.813,0,0,0-17.794,17.793V524.858a17.814,17.814,0,0,0,17.794,17.794H731.4a17.813,17.813,0,0,0,17.793-17.793V295.436A17.814,17.814,0,0,0,731.4,277.642Z"
          transform="translate(-364.974 -184.415)"
          fill="#fff"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M803.833,377.811H730.988a4.161,4.161,0,0,1,0-8.322h72.845a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-465.271 -228.575)"
          fill="#ffe433"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M821,404.859H730.988a4.161,4.161,0,0,1,0-8.322H821a4.161,4.161,0,0,1,0,8.322Z"
          transform="translate(-465.271 -241.579)"
          fill="#ffe433"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M639.755,336.711H572.6A1.994,1.994,0,0,0,570.6,338.7v52.414a1.994,1.994,0,0,0,1.993,1.994h67.159a1.994,1.994,0,0,0,1.994-1.993V338.7a1.994,1.994,0,0,0-1.993-1.994Z"
          transform="translate(-390.158 -212.815)"
          fill="#e6e6e6"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M588.368,398a17.7,17.7,0,0,1-4.571,11.981c-2.937,3.223-7.308,5.36-11.72,4.476-4.062-.814-7.363-4.365-6.952-8.66a6.415,6.415,0,0,1,7.927-5.761c3.859,1,5.4,6.563,2.181,9.174-.772.626-1.88-.469-1.1-1.1a3.89,3.89,0,0,0-1.1-6.448,4.888,4.888,0,0,0-6.367,4.323c-.255,3.621,2.519,6.348,5.921,6.99,3.68.695,7.329-1.189,9.822-3.789a16.072,16.072,0,0,0,4.4-11.185.779.779,0,0,1,1.558,0Z"
          transform="translate(-387.508 -241.923)"
          fill="#2f2e41"
        />
        <circle
          id="Ellipse_38"
          data-name="Ellipse 38"
          cx="27.787"
          cy="27.787"
          r="27.787"
          transform="translate(188.282 110.408)"
          fill="#ffe433"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M636.453,370.719l-.029-1.038c1.932-.054,3.635-.175,4.915-1.11a3.192,3.192,0,0,0,1.236-2.351,1.825,1.825,0,0,0-.6-1.5,3.145,3.145,0,0,0-3.213-.028l-.859.394,1.648-12.04,1.029.141-1.4,10.242a3.666,3.666,0,0,1,3.467.5,2.841,2.841,0,0,1,.966,2.332,4.223,4.223,0,0,1-1.661,3.153C640.307,370.611,638.17,370.671,636.453,370.719Z"
          transform="translate(-421.805 -220.667)"
          fill="#2f2e41"
        />
        <rect
          id="Rectangle_82"
          data-name="Rectangle 82"
          width="5.593"
          height="1.038"
          transform="translate(223.864 133.399)"
          fill="#2f2e41"
        />
        <rect
          id="Rectangle_83"
          data-name="Rectangle 83"
          width="5.593"
          height="1.038"
          transform="translate(206.211 133.399)"
          fill="#2f2e41"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M609.572,437.037a27.521,27.521,0,0,1,6.693-3.079,4.448,4.448,0,0,1,.01-2.445,4.894,4.894,0,0,1,4.74-3.442h6.77a4.912,4.912,0,0,1,4.751,3.448,4.43,4.43,0,0,1,.01,2.44,27.6,27.6,0,0,1,6.693,3.079Z"
          transform="translate(-408.895 -256.741)"
          fill="#2f2e41"
        />
        <path
          id="Path_134"
          data-name="Path 134"
          d="M691.2,342.554a6.007,6.007,0,0,0-1.833-1.49,4.345,4.345,0,0,0-2-.493,4.554,4.554,0,0,0-2.653.893c-2.113,1.5-3.577,4.72-3.577,8.453,0,4.683,2.305,8.567,5.3,9.242a4.287,4.287,0,0,0,.929.1c3.432,0,6.23-4.19,6.23-9.346A11.476,11.476,0,0,0,691.2,342.554Z"
          transform="translate(-443.306 -214.671)"
          fill="#3f3d56"
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M582.688,341.241h-.005a4.454,4.454,0,0,0-2.311-.67,4.344,4.344,0,0,0-2,.493,6.008,6.008,0,0,0-1.833,1.49l-.005.005a11.5,11.5,0,0,0-2.393,7.357c0,4.029,1.7,7.466,4.091,8.78a4.435,4.435,0,0,0,2.139.566,4.005,4.005,0,0,0,.5-.031h.006c3.2-.384,5.727-4.413,5.727-9.314C586.6,345.991,584.978,342.622,582.688,341.241Z"
          transform="translate(-391.86 -214.671)"
          fill="#3f3d56"
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M580.544,334.27a.779.779,0,0,0,.738-1.028,30.373,30.373,0,1,1,58.507-3.414.779.779,0,1,0,1.524.321,31.931,31.931,0,1,0-61.507,3.59A.779.779,0,0,0,580.544,334.27Z"
          transform="translate(-393.781 -191.138)"
          fill="#3f3d56"
        />
        <path
          id="Path_137"
          data-name="Path 137"
          d="M643.729,329.266a3.894,3.894,0,0,1-.6,4.821c-1.508,1.685-3.914,1.791-6,1.479-2.344-.351-4.83-1.449-7.2-.709-2.023.631-3.465,2.465-5.566,2.926-1.863.409-4.092-.132-4.745-2.118-.314-.954,1.19-1.363,1.5-.414.423,1.285,2.247,1.259,3.292.9,1.675-.57,2.967-1.958,4.613-2.621,1.942-.781,4.048-.429,6.024.017,1.863.42,4.007,1.042,5.863.258,1.373-.58,2.466-2.407,1.478-3.755-.593-.809.758-1.587,1.345-.786Z"
          transform="translate(-413.701 -209.074)"
          fill="#2f2e41"
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M779.582,505.785h-98.8a4.161,4.161,0,0,1,0-8.322h98.8a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-441.13 -290.105)"
          fill="#e6e6e6"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M796.746,532.833H680.777a4.161,4.161,0,1,1,0-8.322H796.746a4.161,4.161,0,0,1,0,8.322Z"
          transform="translate(-441.13 -303.109)"
          fill="#e6e6e6"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M779.582,603h-98.8a4.161,4.161,0,1,1,0-8.322h98.8a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-441.13 -336.845)"
          fill="#e6e6e6"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M796.746,630.046H680.777a4.161,4.161,0,0,1,0-8.322H796.746a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-441.13 -349.849)"
          fill="#e6e6e6"
        />
        <circle
          id="Ellipse_39"
          data-name="Ellipse 39"
          cx="17.653"
          cy="17.653"
          r="17.653"
          transform="translate(182.89 200.732)"
          fill="#e6e6e6"
        />
        <circle
          id="Ellipse_40"
          data-name="Ellipse 40"
          cx="17.653"
          cy="17.653"
          r="17.653"
          transform="translate(182.89 251.614)"
          fill="#e6e6e6"
        />
        <path
          id="Path_142"
          data-name="Path 142"
          d="M779.582,701h-98.8a4.161,4.161,0,1,1,0-8.322h98.8a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-441.13 -383.963)"
          fill="#e6e6e6"
        />
        <path
          id="Path_143"
          data-name="Path 143"
          d="M796.746,728.046H680.777a4.161,4.161,0,1,1,0-8.322H796.746a4.161,4.161,0,1,1,0,8.322Z"
          transform="translate(-441.13 -396.968)"
          fill="#e6e6e6"
        />
        <circle
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="17.653"
          cy="17.653"
          r="17.653"
          transform="translate(182.89 302.495)"
          fill="#e6e6e6"
        />
      </g>
    </svg>
  );
}
